import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { usePayment } from 'publisher/store'
import {
  getActivePricePlan,
  getProduct,
} from 'publisher/store/payment/paymentSelectors'

export function useAreInstallmentsVisible() {
  const pricePlan = usePayment(getActivePricePlan)
  const product = usePayment(getProduct)

  return pricePlan
    ? pricePlan.type === PricePlanTypeEnum.OneShot
    : !!product?.id
}
